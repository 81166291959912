/* src/components/Header/Header.css */
:root{
  --padding-value: 0 14vw;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100px;
    background-color: #352570;
    color: white;
    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: var( --padding-value);
  }

.logo img {
    max-width: 150px;  /* Set maximum size */
    height: auto;      /* Maintain aspect ratio */
  }
  
  .nav-links ul {

    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .nav-links ul li {
    margin-left: 2rem;
  }
  

  /* Styling for NavLinks */
.nav-links a {
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 24.38px;
    text-align: left;
    display: inline-block;
  }
  /* Styling for NavLinks */
.nav-links li {

    list-style: none;
    display: inline-block;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
  }
  
 /* Hover effect for NavLinks */
.nav-links li {
  position: relative; /* Ensure the pseudo-element is positioned relative to the li */
}

.nav-links li::after {
  content: ''; /* Create a blank content for the line */
  position: absolute;
  left: 0;
  bottom: 0; /* Position the line at the bottom */
  width: 0; /* Start with a width of 0 */
  height: 2px; /* Thickness of the underline */
  background-color: #ffffff; /* Color of the underline, change as needed */
  transition: width 0.3s ease; /* Smooth transition effect */
}

.nav-links li:hover::after {
  width: 100%; /* On hover, expand the underline to full width */
}

/* Hamburger Menu Styling */
.hamburger {
    display: none;
    cursor: pointer;
    width: 30px;
    height: 20px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
  
  .hamburger .line {
    width: 100%;
    height: 4px;
    background-color: #fff;
    transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition for line rotation */
  }
  @media screen and (max-width: 1271px) {
    :root{
      --padding-value :0 5vw;
    }
  }
  /* Responsive layout */
  @media screen and (max-width: 935px) {
    :root{
      --padding-value :0 10%;
    }
 
    .hamburger {
        display: flex;
        z-index: 1000;  /* Ensure the hamburger is above the nav links */

      }
    
   /* Mobile Navigation (Starts off-screen, hidden) */
.nav-links {
  position: absolute;
  top: 100px;  /* Adjust based on your header's height */
  left: 0;
  right: 0;
  background-color: #352570;
  flex-direction: column;
  gap: 20px;
  padding:  20px 9vw;
  display: flex;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in; /* Transition for sliding and fading */
  pointer-events: none;
  cursor: default;
  text-decoration: none;
 }

/* Show the nav when open (Slide it in and fade it in) */
.nav-links.open {
  pointer-events: all;
  cursor: default;
  opacity: 1; /* Make it visible */
  z-index: 999;
}

    
      .hamburger.open .line:nth-child(1) {
        transform: rotate(45deg) translateY(11px);  /* Rotate top line */
      }
    
      .hamburger.open .line:nth-child(2) {
        opacity: 0;  /* Hide the middle line */
      }
    
      .hamburger.open .line:nth-child(3) {
        transform: rotate(-45deg) translateY(-11px);  /* Rotate bottom line */
      }
    .nav-links ul {
      flex-direction: column;
      margin-top: 1rem;
    }
  
    .nav-links ul li {
      margin-left: 0;
      margin-bottom: 1rem;
    }
  
    .nav-links ul li a {
      font-size: 1.5rem;
    }
  
    .logo img {
      max-width: 120px;  /* Make the logo smaller for mobile */
    }
  }