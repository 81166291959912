.outer-container {
    width: 100%;
    height: 100vh;
  }
  .col-fix-height{
    height: 70px;
  }
  .col-fix-height-sm{
    height: 60px;
  }
  .scroll-container {
    width: 900px;
    overflow: hidden;
  }
  
  .scroll-wrapper {
    display: flex;
  }
  
  .img-container {
    min-width: 150px; /* Adjust as per image size */
    padding: 10px;
  }
 @media (max-width:901px) {
    .scroll-container {
        width: 600px;
        align-self: center!important;
      }
 } 
 @media (max-width:600px) {
    .scroll-container {
        width: 80vw;
        align-self: center!important;
      }
 }