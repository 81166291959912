  
  .title-hero {
      font-size: 4rem; /* Responsive font size */
    line-height: 2.2; /* Relative line height */
    text-align: center;
    color: #352570;
    padding-bottom: 120px;
    font-weight: 700;
  }
@media(max-width:576px) {
    .about-description{
        width: 80% !important;
    }
}
@media(max-width:576px) {
    .title-hero{
      font-size: 3rem;
    }
  }
  