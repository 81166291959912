.custom-card {
    
    width: 203px; /* Base max width */
    height: 253px;
    aspect-ratio: 3 / 4; /* Keep the aspect ratio consistent */
    background-image: repeating-linear-gradient(23deg, #432687, #432687 12px, transparent 12px, transparent 22px, #432687 22px), repeating-linear-gradient(113deg, #432687, #432687 12px, transparent 12px, transparent 22px, #432687 22px), repeating-linear-gradient(203deg, #432687, #432687 12px, transparent 12px, transparent 22px, #432687 22px), repeating-linear-gradient(293deg, #432687, #432687 12px, transparent 12px, transparent 22px, #432687 22px);
    background-size: 3px 100%, 100% 3px, 3px 100% , 100% 3px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    display: flex;
  flex-direction: column; /* Align children in a column */
  margin-bottom: 20px; /* Adjust the value as needed */


  } 
  .card-img-top {
    max-width: 140px;
    height: auto; /* Set a fixed height for images */
    }
  .card-title {
    font-weight: bold;
    text-align: center;
    padding-top: 10px;
  }
  .card-image{
    height: 405px !important;
    width: auto;
    align-self: center;
    padding:30px 20px 20px 20px;
    flex: 1; /* This allows the image to take up half of the card */
    object-fit: contain; /* Ensure the image covers the space without distortion */
  
  }
  .card-text{
    padding: 20px;
    background-color: transparent !important;
    flex: 1; /* This allows the body to take up the remaining half of the card */
    flex-grow: 1;
    text-align: center; /* Center text inside the body */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 0; 
  }
  
  /* Make the card resize smaller while maintaining the aspect ratio */
  @media (max-width: 768px) {
    .custom-card {
     width: 180px; /* Shrink card size on medium screens */
      aspect-ratio: 3 / 4; /* Keep the aspect ratio consistent */
    }
  }
  
  @media (max-width: 576px) {
    .custom-card {
      width: 180px; /* Shrink card further on small screens */
      aspect-ratio: 3 / 4; /* Keep the aspect ratio consistent */
    }
  }
  