  
  .title-team {
 text-wrap: none;
 width: 100%;
}
.team-member-container{
    padding:0 11vw;
}
.team-photo{
    padding-top: 0px;
}
@media(max-width:576px) {
  .title-team{
    font-size: 2.6rem;
    line-height: 1.5; /* Adjust for desired line spacing */
  }
}
.linkedin{
  color: black;
  font-size: 2rem;
}
.linkedin:hover{
  color: #352570;
}
.card-img-top {
  max-width: 120px;
  height: auto; /* Set a fixed height for images */
  }
.title-static {
  height: 50px; /* Set a fixed height */
  display: flex; /* Center the text vertically */
  align-items: center; /* Center the text vertically */
  justify-content: center; /* Center the text horizontally */
  overflow: hidden; /* Hide any overflow */
  white-space: initial; /* Prevent the text from wrapping to a new line */
  text-overflow: ellipsis; /* Add ellipsis (...) for overflow text */
}
.title-static p {
  margin: 0; /* Remove default margin */
  font-size: 2vw; /* Base font size for responsiveness */
}

/* Media queries for adjusting font size on larger screens */
@media (min-width: 768px) {
  .title-static p {
    font-size: .6rem; /* Set a specific font size for tablets */
  }
}
/* Media queries for adjusting font size on larger screens */
@media (max-width: 768px) {
  .linkedin{ 
    font-size: 1.2rem;
  }
}

@media (min-width: 992px) {
  .title-static p {
    font-size: .8rem; /* Set a specific font size for desktops */
  }
}

@media (min-width: 1200px) {
  .title-static p {
    font-size: 1.1rem; /* Further adjustment for larger desktops */
  }
}
.card-body{
  border: none;
}