.games-card {
    
    aspect-ratio: 3 / 4; /* Keep the aspect ratio consistent */
    background-size: 3px 100%, 100% 3px, 3px 100% , 100% 3px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    display: flex;
     flex-direction: column; /* Align children in a column */
   padding: 0px;
  } 
