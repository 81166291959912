/* General container styling */
.carousel-container {
     width: 100%;
    height:  100vh; /* Adjust height to be full screen minus the 70px offset */
    overflow: hidden; /* Hide overflow to prevent unwanted scrollbars */
 }
.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45); /* Adjust the alpha value (0.5) to dim */
  z-index: 1;
}
/* Carousel images container */
.carousel-images {
    display: flex;
    transition: transform 0.5s ease-in-out; /* Smooth sliding effect */
    height: 100%; /* Ensure images take full height */
    width: 100%; /* Full width of the container */
}

/* Carousel slide (each image) */
.carousel-slide {
    min-width: 100%; /* Each slide takes up full width */
    box-sizing: border-box;
    position: relative;
}

/* Image styling */
.carousel-image {
    width: 100%;
    height: 100%; /* Full height of the carousel container */
    object-fit: cover; /* Ensure images cover the container without stretching */
    object-position: center; /* Default scaling from the center */
    z-index: 0;
}

/* Title navigation styling */
.title-container {
    position: absolute;
    bottom: 12%; /* Adjust title positioning based on container's height */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px; /* Space between titles */
}

.title {
    font-size: 1rem;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    transition: color 0.3s ease, text-shadow 0.3s ease;
    /* Apply drop shadow effect for titles */
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}

/* Active title styling */
.title.active {
    color: white; /* Highlight active title */
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.8); /* Stronger shadow for active title */
}

/* Button container for positioning */
.carousel-button-container {
    position: absolute;
    bottom: 20%; /* Adjust button position to be at the bottom, but with enough space */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center alignment */
    width: 296px; /* Fixed width */
    height: 79px; /* Fixed height */
    padding-top: 25px;
    gap: 10px;
    opacity: 1;
     box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-100) var(--sds-size-depth-100) var(--sds-size-depth-negative-100) var(--sds-color-black-100);
}

.carousel-button {
    width: 100%;
    height: 100%;
    background-color: #ffffff; /* Button background color */
    color: #352570 ; /* Font color */
    border: none;
    font-size: 24px;
    font-weight: 700;
    box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-100) var(--sds-color-black-200);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
    text-align: center;
    z-index: 9999;
}

.carousel-button:hover {
    background-color:  #352570; /* On hover, change background to white */
    color: #ffff ; /* On hover, change text color to #352570 */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}

/* Mobile responsive styling */
@media (max-width: 768px) {
    .carousel-container {
        /*height: calc(100vh - 70px); Full screen height minus 70px offset */
    }

    .carousel-image {
        object-position: center; /* Ensure it scales from the center */
    }

    .title-container {
        bottom: 10%; /* Adjust button position to be at the bottom, but with enough space */
   
    }

    .title {
        font-size: 0.9rem; /* Smaller font on mobile */
    }

    .carousel-button-container {
        bottom: 20%; /* Adjust button position to be at the bottom, but with enough space */
   
    }
}

@media (max-width: 480px) {

    .carousel-image {
        object-position: center; /* Ensure it scales from the center */
    
    }

    .carousel-button-container {
        bottom: 20%; /* Adjust button position to be at the bottom, but with enough space */
   
    }

    .title {
        font-size: 0.8rem; /* Further reduce font size for very small screens */
    }
}
.carousel-content {
  width: 25%;
  position: absolute;
  top:33% ; /* Center vertically */
  left: 14vw; /* Adjust space from the left */
  transform: translateY(-50%); /* Center align */
  color: white; /* Text color */
  z-index: 2;
 }

.carousel-title {
position: relative;
  font-weight: bold;
  width: 100%;
  font-size: 3vw;
 
}

.carousel-description {
    padding-top: 30px;
position: relative;
  font-size: 1.2rem; /* Adjust description size */
  margin-top: 10px; /* Space between title and description */
  width: 100%;
  text-align: left;
}

/* Mobile responsive styling */
@media (max-width: 999px) {
    .carousel-content {    
        top: 60%; /* Center vertically */
       }
    
}

@media (max-width: 1300px) {
    .carousel-content {    
        width: 40%; /* Center vertically */
       }
       .carousel-title{
        font-size: 4vw;
    }
}/* Mobile responsive styling */
@media (max-width: 728px) {
    .carousel-content {    
        width: 75%; 
        top: 60%; 
         }
    .carousel-description{
        text-align: justify;
        font-size: 4vw;
    }
    .carousel-title{
        font-size: 7vw;
    }

    
}