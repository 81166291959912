.footer {
    height: 100px; /* Set the desired height */
    background-color: #343a40; /* Bootstrap's bg-dark color */
    display: flex; /* Flexbox for centering content */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
  }
  .footer-title {
    font-family: 'Montserrat', sans-serif; /* Specify font */
    font-style: normal; /* Normal font style */
    font-weight: 700; /* Bold weight */
    font-size: 70px; /* Default font size */
    line-height: 80px; /* Adjust line height for better spacing */
    text-align: left;
    color: #000000; /* Text color */
    align-items: flex-end; /* Center items vertically */
    padding-left: 10rem;
  }
  .footer-text{
    text-align: left;
  }

  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .footer-title {
      font-size: 60px; /* Reduce font size for large screens */
      line-height: 72px; /* Adjust line height for consistency */
      padding-left: 5rem;
    }
  }
  
  @media (max-width: 992px) {
    .footer-title {
      font-size: 50px; /* Further reduce for medium screens */
      line-height: 60px; /* Adjust line height for consistency */
      padding-left: 5rem;
    }
  }
  
  @media (max-width: 768px) {
    .footer-title {
      font-size: 40px; /* Adjust for small tablets */
      line-height: 48px; /* Adjust line height for consistency */
      padding-left: 2rem;
    }
  }
  
  @media (max-width: 576px) {
    .footer-title {
      font-size: 2.5rem; /* Smallest size for mobile screens */
      line-height: 36px; /* Adjust line height for consistency */
      text-align: center;
      padding-left: 0rem;
      align-self: center;
      padding: 0 15px;
    }
  }
  
  .list-group-item {
    padding: 15px; /* Default padding */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .list-group-item {
      padding: 10px; /* Less padding on smaller screens */
    }
    
    .list-group-item i {
      font-size: 4vw; /* Adjust icon size for smaller screens */
    }
    .footer-text{
        text-align: center;
        font-size: 0.7rem !important;
      }
    .list-group-item p {
      font-size: 1rem; /* Smaller text for smaller screens */
      line-height: 1.4; /* Adjust line height for better readability */
    }
  }
  
  @media (max-width: 576px) {
    .list-group-item {
      padding: 5px; /* Minimal padding on very small screens */
    }
    
    .list-group-item i {
      font-size: 3.5vw; /* Further reduce icon size */
    }
  
    .list-group-item p {
      font-size: 0.9rem; /* Further reduce text size */
      line-height: 1.3; /* Adjust line height for smaller screens */
    }
    .footer-text{
        text-align: center;
        font-size: 1rem !important;
      }
  }
  .responsive-title {
    margin: 0;
    font-size: 20px; /* Adjust as needed for desktop size */
    white-space: normal;
    overflow: visible;
    text-overflow: ellipsis;
  }


@media (max-width: 576px) {
.footer-details-container{
    align-items: center;
}
.footer-container{
    align-items: start !important;
    font-size: medium !important;
}
}