/* components/ScrollDownButton/ScrollDownButton.css */

.scroll-down-container {
  position: absolute;  /* Anchor it inside the carousel */
  bottom: 20px;        /* Adjust this value based on spacing preferences */
  left: 50%;           /* Center it horizontally */
  transform: translateX(-50%);  /* Ensure it is perfectly centered */
  cursor: pointer;     /* Change cursor to pointer */
  z-index: 10; 
 
}

.scroll-text {
  font-size: 18px;
  font-weight: bold;
  color: white; /* White font color */
   margin-bottom: 10px; /* Space between text and the arrow */
}
.arrow {
  font-size: 24px; /* Adjust the size of the arrow */
  color: white; /* Static white arrow color */
 
}

